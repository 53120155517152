import React, { useState, useEffect, useRef } from "react";
import './SchemaField.css';
import AddIcon from '../../icons/add_icon.png';
import RemoveIcon from '../../icons/trash_icon.png';
import AddMultipleIcon from '../../icons/add_multiple_icon.png'

const SchemaField = ({ field, updateField, removeField, schemaElements }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newInput, setNewInput] = useState('');
  const [isAddingMultiple, setIsAddingMultiple] = useState(false);
  const [multipleInputs, setMultipleInputs] = useState('');
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const multipleInputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleAddInput = () => {
    setIsAdding(!isAdding);
    setNewInput('');
  };

  const toggleAddMultipleInput = () => {
    setIsAddingMultiple(!isAddingMultiple);
    setMultipleInputs('');
  };

  const handleInputChange = (e) => {
    setNewInput(e.target.value);
  };

  const handleMultipleInputChange = (e) => {
    setMultipleInputs(e.target.value);
  };

  const handleAddInput = () => {
    if (!newInput) {
      alert("Please enter value.");
      return;
    }
    if (newInput.trim()) {
      const updatedInputs = [...field.inputs, newInput];
      updateField(field.fieldName, updatedInputs);
      setIsAdding(false);
      setNewInput('');
    }
  };

  const handleAddMultipleInputs = () => {
    if (!multipleInputs.trim()) {
      alert("Please enter values.");
      return;
    }
    const newInputs = multipleInputs.split('\n').map(input => input.trim()).filter(input => input);
    if (newInputs.length) {
      const updatedInputs = [...field.inputs, ...newInputs];
      updateField(field.fieldName, updatedInputs);
      setIsAddingMultiple(false);
      setMultipleInputs('');
    }
  };

  const handleRemoveInput = (inputToRemove) => {
    if (field.inputs.length === 1) {
      alert("Field needs at least one input");
      return;
    }
    const updatedInputs = field.inputs.filter(input => input !== inputToRemove);
    updateField(field.fieldName, updatedInputs);
  };

  const handleRemoveField = () => {
    removeField(field.fieldName);
  };

  const getInputs = () => {
    return field.inputs.map((input, inputIndex) => (
      <div key={inputIndex} className="input-container">
        <p className="handle-field-input-text">{input}</p>
        <img onClick={() => handleRemoveInput(input)} className='handle-field-btn-small' src={RemoveIcon} alt='remove field'/>
      </div>
    ));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddInput();
    }
  };

  const handleCancelAddInput = () => {
    setIsAdding(false);
    setNewInput('');
  };

  const handleCancelAddMultipleInput = () => {
    setIsAddingMultiple(false);
    setMultipleInputs('');
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setIsAdding(false);
      setIsAddingMultiple(false);
    }
  };

  useEffect(() => {
    if (isAdding && inputRef.current) {
      inputRef.current.focus();
    }
    if (isAddingMultiple && multipleInputRef.current) {
      multipleInputRef.current.focus();
    }
  }, [isAdding, isAddingMultiple]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`field-container ${isOpen ? 'open' : ''}`}>
      <div className="field-header">
        <p className={`dropdown-button ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
          &gt;
        </p>
        <h4>{field.fieldName}</h4>
        {isAdding ? (
          <div ref={formRef}>
            <input
              type="text"
              value={newInput}
              placeholder='Add field data'
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              className="input-box"
              ref={inputRef}
            />
            <button onClick={handleAddInput} className='handle-add-field-btn'>
              Submit
            </button>
            <button onClick={handleCancelAddInput} className='handle-add-field-btn'>
              Cancel
            </button>
          </div>
        ) : isAddingMultiple ? (
          <div ref={formRef}>
            <textarea
              value={multipleInputs}
              placeholder="Each line is a different value"
              onChange={handleMultipleInputChange}
              className="input-box"
              ref={multipleInputRef}
            />
            <button onClick={handleAddMultipleInputs} className='handle-add-field-btn'>
              Submit
            </button>
            <button onClick={handleCancelAddMultipleInput} className='handle-add-field-btn'>
              Cancel
            </button>
          </div>
        ) : (
          <>
            <img onClick={toggleAddInput} className='handle-field-btn'
              src={AddIcon} alt='Add field' title="Add value" />
            <img onClick={toggleAddMultipleInput} className='handle-field-btn'
              src={AddMultipleIcon} alt='Add multiple fields' title="Add multiple values" />
          </>
        )}
        <img onClick={handleRemoveField} className='handle-field-btn'
          src={RemoveIcon} alt='remove field' title="Remove field" />
      </div>
      <div className="input-wrapper">
        {isOpen && getInputs()}
      </div>
    </div>
  );
};

export default SchemaField;
