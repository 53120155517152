import React, { useEffect, useState, useRef } from 'react';
import './SchemaUploadForm.css';
import NestedDropdown from "../nestedDropdown/NestedDropdown";

const SchemaUploadForm = ({ userId, onSubmit, onCancel }) => {
  const [file, setFile] = useState(null);
  const [schema, setSchema] = useState('');
  const [complexTypes, setComplexTypes] = useState([]);
  const [selectedRoot, setSelectedRoot] = useState('');
  const [rootElements, setRootElements] = useState({});
  const [primaryKey, setPrimaryKey] = useState('');
  const [usedComplexTypes, setUsedComplexTypes] = useState([]);
  const [unusedComplexTypes, setUnusedComplexTypes] = useState({});
  const [otherPrimaryKeys, setOtherPrimaryKeys] = useState({});
  const [customerDelimiter, setCustomerDelimiter] = useState('');
  const [customerDelimiterElements, setCustomerDelimiterElements] = useState({});
  const [showPrimaryKeyDropdown, setShowPrimaryKeyDropdown] = useState(true);
  const [includeOverride, setIncludeOverride] = useState(false);
  const [overrideData, setOverrideData] = useState({
    jobStepName: '',
    DefinedAssembleDatasource: '',
    overidenAssembleDataFile: ''
  });
  const formRef = useRef(null);

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        setSchema(content);

        // Call getComplexTypes endpoint
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getComplexTypes`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ schema: content })
          });
          const result = await response.json();
          setComplexTypes(result);
        } catch (error) {
          console.error('Error fetching complex types:', error);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleRootChange = async (e) => {
    setPrimaryKey('');
    setCustomerDelimiter('');
    setCustomerDelimiterElements({});
    const newSelectedRoot = e.target.value;
    setSelectedRoot(newSelectedRoot);
    setUsedComplexTypes([newSelectedRoot]);
    const rootElementsData = await fetchElements(newSelectedRoot, {}, newSelectedRoot);

    if (rootElementsData && rootElementsData.complex) {
      setRootElements(rootElementsData);
    } else {
      setRootElements([]);
      alert("No possible customer delimiters, please select a different root.");
      setSelectedRoot('');
    }
  };

  const handleCustomerDelimiterChange = async (e) => {
    const newCustomerDelimiter = e.target.value;
    setCustomerDelimiter(newCustomerDelimiter);
    const customerDelimiterElementsData = await fetchElements(newCustomerDelimiter, {}, newCustomerDelimiter);
    setCustomerDelimiterElements(customerDelimiterElementsData);
    setUsedComplexTypes([...usedComplexTypes, newCustomerDelimiter]);  // Add the customerDelimiter to usedComplexTypes
  };

  const fetchElements = async (elementName, currentRootElements = {}, currentRoot) => {
    const data = {
      schema: schema,
      root: elementName
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getRootElements`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      const elementData = { ...result }; // Copying result to avoid mutation
      currentRootElements = elementData;

      // Initialize elements as an object if complex
      if (elementData.complex) {
        for (const [element, ref] of Object.entries(elementData.elements)) {
          currentRootElements.elements[element] = await fetchElements(element, {}, currentRoot);
        }
      }

      if (elementName === currentRoot) {
        return currentRootElements;
      }

      return elementData;
    } catch (error) {
      console.error('Error fetching elements:', error);
    }
  };

  const fetchUnusedComplexTypes = async () => {
    const data = {
      schema: schema,
      usedElements: usedComplexTypes
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getRemainingComplexTypes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      // Prepare objects to hold unused complex types and other primary keys
      const currentUnusedComplexTypes = {};
      const initialOtherPrimaryKeys = {};

      // Iterate through result and fetch root elements for each complex type
      for (const item of result) {
        const itemData = {
          schema: schema,
          root: item
        };
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getRootElements`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemData),
          });
          const itemResult = await response.json();
          currentUnusedComplexTypes[item] = itemResult;
          initialOtherPrimaryKeys[item] = {
            elementName: null,
            matchPK: false
          }; // Initialize otherPrimaryKeys with null values and matchPK as false
        } catch (error) {
          console.error('Error fetching root elements:', error);
        }
      }

      // Set state with fetched data
      setUnusedComplexTypes(currentUnusedComplexTypes);
      setOtherPrimaryKeys(initialOtherPrimaryKeys);
    } catch (error) {
      console.error('Error fetching unused complex types:', error);
    }
  };

  useEffect(() => {
    if (primaryKey !== '') {
      fetchUnusedComplexTypes();
    }
  }, [primaryKey]);

  const handleOtherPrimaryKeyChange = (complexType, value) => {
    setOtherPrimaryKeys({
      ...otherPrimaryKeys,
      [complexType]: {
        ...otherPrimaryKeys[complexType],
        elementName: value
      }
    });
  };

  const handleMatchMainPKChange = (complexType) => {
    setOtherPrimaryKeys({
      ...otherPrimaryKeys,
      [complexType]: {
        ...otherPrimaryKeys[complexType],
        matchPK: !otherPrimaryKeys[complexType]?.matchPK
      }
    });
  };

  const handlePrimaryKeyChange = (value) => {
    setPrimaryKey(value);
    setShowPrimaryKeyDropdown(false);
  };

  const handlePrimaryKeyClick = () => {
    setPrimaryKey('');
    setOtherPrimaryKeys({});
    setShowPrimaryKeyDropdown(true);
  };

  const handleSubmit = () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }
    if (!selectedRoot) {
      alert('Please select a root.');
      return;
    }
    if (!customerDelimiter) {
      alert('Please select a customer delimiter.');
      return;
    }
    if (!primaryKey) {
      alert('Please select a primary key.');
      return;
    }
    for (const key in otherPrimaryKeys) {
      if (otherPrimaryKeys.hasOwnProperty(key)) {
        const complexType = otherPrimaryKeys[key];
        if (!complexType.elementName) {
          alert(`Please fill the elementName for ${key}.`);
          return;
        }
      }
    }

    const fileName = file.name.replaceAll('.xsd', '');
    const newSchema = {
      fileName,
      schema,
      root: selectedRoot,
      customerDelimiter,
      primaryKey,
      otherPrimaryKeys,
      testCases: [
        {
          testCaseName: 'default',
          outputFile: fileName + ".xml",
          fields: []
        }
      ],
      includeOverride,
      overrideData
    };
    // Implement further submission logic if needed

    onSubmit(newSchema);
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      if (window.confirm('This will cancel your upload, are you sure?')) {
        onCancel();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOverrideDataChange = (e) => {
    const { name, value } = e.target;
    setOverrideData({
      ...overrideData,
      [name]: value
    });
  };

  return (
    <>
      <div className="schema-upload-form-overlay"></div>
      <div className="schema-upload-form-container" ref={formRef}>
        <h2>Upload Schema</h2>
        <div className="schema-upload-form">
          <div className="form-field">
            <label>Schema:</label>
            <input
              type="file"
              name="newXsdFile"
              accept=".xsd"
              onChange={handleFileSelect}
              className="choose-file"
              id="file-input"
            />
            <label htmlFor="file-input" className="file-input-label">
              {file ? file.name : 'Choose a file'}
            </label>
          </div>
          {file && (
            <div className="form-field">
              <label>Root:</label>
              <select
                id="rootElement"
                value={selectedRoot}
                onChange={handleRootChange}
              >
                <option value="" disabled>Select root element</option>
                {complexTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
            </div>
          )}
          {selectedRoot && (
            <>
              <div className="form-field">
                <label>Customer Delimiter:</label>
                <select
                  id="customerDelimiter"
                  value={customerDelimiter}
                  onChange={handleCustomerDelimiterChange}
                >
                  <option value="" disabled>Select customer delimiter</option>
                  {Object.keys(rootElements.elements || {}).map((element, index) => (
                    <option key={index} value={element}>{element}</option>
                  ))}
                </select>
              </div>
            </>
          )}
          {customerDelimiter && (
            <div className="form-field">
              <label>Primary Key:</label>
              {showPrimaryKeyDropdown ? (
                <NestedDropdown rootElements={customerDelimiterElements} setPrimaryKey={handlePrimaryKeyChange} setUsedComplexTypes={setUsedComplexTypes} />
              ) : (
                <span onClick={handlePrimaryKeyClick} className="primary-key-value">
                  {primaryKey}
                </span>
              )}
            </div>
          )}
          {primaryKey && (
            <>
              <h3>Other Primary Keys</h3>
              {Object.keys(unusedComplexTypes).map((complexType, index) => (
                <div key={index} className="other-primary-key-container">
                  <div className="form-field">
                    <label>{complexType}:</label>
                    <select
                      value={otherPrimaryKeys[complexType]?.elementName || ''}
                      onChange={(e) => handleOtherPrimaryKeyChange(complexType, e.target.value)}
                    >
                      <option value="">Select other primary key</option>
                      {Object.keys(unusedComplexTypes[complexType].elements || {}).map((element, index) => (
                        <option key={index} value={element}>{element}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-field">
                    <label>
                      <input
                        type="checkbox"
                        checked={otherPrimaryKeys[complexType]?.matchPK || false}
                        onChange={() => handleMatchMainPKChange(complexType)}
                      /> Match main primary key
                    </label>
                  </div>
                </div>
              ))}
              <div className="form-field">
                <label>
                  <input
                    type="checkbox"
                    checked={includeOverride}
                    onChange={() => setIncludeOverride(!includeOverride)}
                  /> Include Override File
                </label>
              </div>
              {includeOverride && (
                <>
                  <div className="form-field">
                    <label>Job Step Name:</label>
                    <input
                      type="text"
                      name="jobStepName"
                      value={overrideData.jobStepName}
                      onChange={handleOverrideDataChange}
                    />
                  </div>
                  <div className="form-field">
                    <label>Defined Assemble Datasource:</label>
                    <input
                      type="text"
                      name="DefinedAssembleDatasource"
                      value={overrideData.DefinedAssembleDatasource}
                      onChange={handleOverrideDataChange}
                    />
                  </div>
                  <div className="form-field">
                    <label>Overridden Assemble Data Path:</label>
                    <input
                      type="text"
                      name="overidenAssembleDataFile"
                      value={overrideData.overidenAssembleDataFile}
                      onChange={handleOverrideDataChange}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className="form-actions">
            <button onClick={handleSubmit} className="submit-button">Submit</button>
            <button onClick={onCancel} className="cancel-button">Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchemaUploadForm;
