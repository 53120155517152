// Navbar.js
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';  // This imports Auth specifically from the auth module
import './Navbar.css';
import logo from './ECM-TEK-FULL-COLOR-Logo.png';
import help from '../../icons/help_icon.png'
import logout from '../../icons/exit_icon.png'
const Navbar = ({ userId, setUserId, setUserDetails }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login'); // Redirect to the login page or any other route after signing out
    } catch (error) {
      console.error('Error signing out: ', error);
    }
    setUserId(null);
    setUserDetails(null);
    // Redirect to login page
    navigate('/login');
  };


  return (
      <nav className="navbar">
          <div className="navbar-logo">
              <Link to="/">
                  <img src={logo} alt="ECM-Tek" className="ecm-image" title="ECM TEK TeCaSi"/>
              </Link>
          </div>
          <div className="navbar-links">
              <a href="https://customer.support.ecmtek.net/servicedesk/customer/portal/4" target="_blank"
                 rel="noopener noreferrer">
                  <img src={help} alt="help icon" className="help-icon" title="Support Portal"/>
              </a>


              {userId && (
                  <button onClick={handleLogout}>
                      <img src={logout} alt="Logout Button" className="logout-image" title="Logout"/>
                  </button>
              )}
          </div>
          <div>
          </div>
      </nav>
  );
};

export default Navbar;
