import React, {useState, useEffect, useRef} from 'react';
import Schema from "../../components/schema/Schema";
import SchemaUploadForm from "../../components/schemaUploadForm/SchemaUploadForm";
import './XMLGeneration.css';
import add from '../../icons/add_icon.png';
import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';
import UpdateIcon from "../../icons/update_icon.png";
import axios from "axios";

const XMLGeneration = ({ userDetails, userId }) => {
  const [schemas, setSchemas] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const fileInputRef = useRef(null); // Move fileInputRef to the outer component scope

  const SocketConnection = () => {
    const [clientID, setClientID] = useState('');

    useEffect(() => {
      const newClientID = uuidv4();
      setClientID(newClientID);
      const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);

      socket.emit('connection', { clientID: newClientID, message: userId });

      socket.on('response', (data) => {
      });
      // Take a message from the backend (FetchData) and update all clientID's accordingly
      socket.on('FetchData', (schemaData) => {
      if (schemaData) {
        fetchSchemas()
      } else {
        console.error("Received malformed data:", schemaData);
      }
      });

      return () => {
        socket.disconnect();
      };
    }, []);
  };

  SocketConnection();

  const fetchSchemas = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}/schemas`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        const extractedSchemas = data.map((item) => {
          const newData = { ...item.schema };
          newData._id = item._id;
          return newData;
        });
        setSchemas(extractedSchemas);
      } else {
        console.error('Failed to fetch schemas:', data.error);
      }
    } catch (error) {
      console.error('Error fetching schemas:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchSchemas();
    }
  }, [userId]);

  const handleSchemaDelete = (deletedSchema) => {
    setSchemas(schemas.filter((schema) => schema !== deletedSchema));
  };

  const getSchemas = () => {
    if (!schemas || schemas.length === 0) {
      return <div className="schema-sub-name">You have no schemas</div>;
    }

    return schemas.map((schema, schemaIndex) => (
      <div key={schemaIndex} className="schema">
        <Schema schema={schema} userId={userId} onDelete={handleSchemaDelete} />
      </div>
    ));
  };

  const handleUploadFormSubmit = async (newSchema) => {
    setShowUploadForm(false);
    const schemaData = {
          'userId': userId,
          'schema': newSchema
        }
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/addSchema`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(schemaData)
          });
          const data = await response.json();
          if (response.ok) {
            setSchemas([...schemas, newSchema]);
          } else {
            console.error('Failed to add schema:', data.error);
          }
        } catch (error) {
          console.error('Error adding schema:', error);
        }
  };

  return (
    <div>
      <div className="schema-list-wrapper">
        {getSchemas()}
      </div>
      {!showUploadForm && (
          <button className="custom-file-upload" onClick={() => setShowUploadForm(true)}>
            <img src={add} alt="Add Schema" className="add-button" title="Add new schema"/>
          </button>
      )}

      {showUploadForm && (
        <SchemaUploadForm userId={userId} onSubmit={handleUploadFormSubmit}
          onCancel={() => setShowUploadForm(false)} />
      )}
    </div>
  );
};

export default XMLGeneration;
