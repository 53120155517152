import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
        <p>© 2024 ECM Tek LLC. All rights reserved.</p>
        <div className="footer-bottom">
            <a target="_blank" rel="noopener noreferrer"
               href="https://www.ecm-tek.com/tecasi-copyright-and-terms-of-use">Terms of Use</a>
            <p>v20240924</p>
        </div>
    </footer>
  );
};

export default Footer;
