import React, { useState, useEffect, useRef } from "react";
import SchemaTestCase from "../schemaTestCase/SchemaTestCase";
import "./Schema.css";
import axios from "axios";
import AddIcon from "../../icons/add_icon.png";
import GenerateIcon from "../../icons/generate_icon.png";
import ShareIcon from "../../icons/share_icon.png";
import UpdateIcon from "../../icons/update_icon.png";


const Schema = ({ schema, userId, onDelete }) => {
  const [testCases, setTestCases] = useState(schema.testCases);
  const [isNewTestCaseAdding, setIsNewTestCaseAdding] = useState(false);
  const [newTestCaseName, setNewTestCaseName] = useState("");
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [isAddingField, setIsAddingField] = useState(false)
  const [newFieldInput, setNewFieldInput] = useState("");
  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [editingTestCase, setEditingTestCase] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const formRef = useRef(null);
  const fileInputRef = useRef(null);
  const shareFormRef = useRef(null);
  let working = false;
  let file = ""; // do not like this implementation, change at a later date

  useEffect(() => {
    setTestCases(schema.testCases);
  }, [schema]);

  const updateTestCase = async (testCaseName, newFields) => {
    const updatedTestCases = testCases.map((testCase) =>
      testCase.testCaseName === testCaseName ? { ...testCase, fields: newFields } : testCase
    );

    setTestCases(updatedTestCases);
    // Update schema with the new test case
    const updatedSchema = { ...schema, testCases: updatedTestCases };
    await updateSchemaInBackend(updatedSchema);
  };

  const updateTestCaseName = async (oldName, newName) => {
    // Trim and remove spaces from the new test case name for the outputFile
    const sanitizedNewName = newName.trim().replaceAll(' ', '');

    const updatedTestCases = testCases.map((testCase) =>
      testCase.testCaseName === oldName
        ? {
          ...testCase,
          testCaseName: newName,
          outputFile: `${schema.fileName}-${sanitizedNewName}.xml`
        }
        : testCase
    );

    setTestCases(updatedTestCases);
    // Update schema with the new test case names
    const updatedSchema = { ...schema, testCases: updatedTestCases };
    await updateSchemaInBackend(updatedSchema);
  };

  const parseSchemaElements = (schema, excludeElements = []) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(schema.schema, "application/xml");
    const elementNodes = doc.getElementsByTagName("xs:element");
    const options = [];
    const elementsToRemove = new Set([schema.root, schema.customerDelimiter, schema.primaryKey, ...excludeElements]);

    if (schema.otherPrimaryKeys) {
      Object.keys(schema.otherPrimaryKeys).forEach((key) => {
        const complexType = schema.otherPrimaryKeys[key];
        if (complexType && complexType.elementName) {
          elementsToRemove.add(complexType.elementName);
          // Also remove the complexType itself
          elementsToRemove.add(key);
        }
      });
    }

    for (let i = 0; i < elementNodes.length; i++) {
      const elementName = elementNodes[i].getAttribute("name");
      if (elementName && !elementsToRemove.has(elementName)) {
        options.push({ name: elementName, value: elementName });
      }
    }

    return options;
  };

  const schemaElements = parseSchemaElements(schema);

  const getSchemaObj = () => {
    if (schema?.root) {
      return {
        primaryKey: schema.primaryKey,
        schema: schema.schema,
        root: schema.root,
        otherPrimaryKeys: schema.otherPrimaryKeys,
        includeOverride: schema.includeOverride,
        overrideData: schema.overrideData
      };
    }
    return {
      primaryKey: schema.primaryKey,
      schema: schema.schema,
    };
  };

  const toggleAddNewTestCase = () => {
    setIsNewTestCaseAdding(!isNewTestCaseAdding);
    setNewTestCaseName("");
    setSelectedFieldName("");
    setNewFieldInput("");
  };

  const handleNewTestCaseNameChange = (e) => {
    setNewTestCaseName(e.target.value);
  };

  const handleFieldNameChange = (e) => {
    setSelectedFieldName(e.target.value);
  };

  const handleNewFieldInputChange = (e) => {
    setNewFieldInput(e.target.value);
  }

  const handleRemoveTestCase = async (testCaseName) => {
    const updatedTestCases = testCases.filter((testCase) => testCase.testCaseName !== testCaseName);
    setTestCases(updatedTestCases);
    // Update schema with the removed test case
    const updatedSchema = { ...schema, testCases: updatedTestCases };
    await updateSchemaInBackend(updatedSchema);
  };

  const doesNameExist= (name) => {
    return testCases.some(tc => tc.testCaseName === name);
  }

  const handleAddNewTestCase = async () => {
    if (!newTestCaseName) {
      alert("Please enter a name for the test case.");
      return;
    }
    if (newTestCaseName === "default") {
      alert('Test case name cannot be "default".');
      return;
    }
    // Check if the new test case name already exists
    const nameExists = doesNameExist(newTestCaseName)
    if (nameExists) {
      alert("A test case with this name already exists.");
      return;
    }
    if (!selectedFieldName) {
      alert("Please select an initial field.");
      return;
    }
    if (!newFieldInput) {
      alert("Please enter an initial value.");
      return;
    }

    if (selectedFieldName.trim() && newFieldInput.trim()) {
      const newField = {
        fieldName: selectedFieldName,
        inputs: [newFieldInput],
      };
      const newTestCase = {
        testCaseName: newTestCaseName,
        outputFile: schema.fileName + "-" + newTestCaseName.replaceAll(' ', '')+ ".xml",
        fields: [newField],
      };
      const updatedTestCases = [...testCases, newTestCase];
      setTestCases(updatedTestCases);

      // Update schema with the new test case
      const updatedSchema = { ...schema, testCases: updatedTestCases };
      await updateSchemaInBackend(updatedSchema);

      setIsNewTestCaseAdding(false);
    }
  };


  const handleDuplicateTestCase = async (testCase) => {
    const newTestCaseName = `Copy of ${testCase.testCaseName}`;
    const newTestCase = {
      ...testCase,
      testCaseName: newTestCaseName,
      outputFile: schema.fileName + "-" + newTestCaseName.replaceAll(' ', '') + ".xml",
    };
    const updatedTestCases = [...testCases, newTestCase];
    setTestCases(updatedTestCases);
    setEditingTestCase(newTestCaseName);  // Set the new test case in edit mode

    const updatedSchema = { ...schema, testCases: updatedTestCases };
    await updateSchemaInBackend(updatedSchema);
  };

  const updateSchemaInBackend = async (updatedSchema) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/updateSchema`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ userId: userId, schema: updatedSchema, fileName: schema.fileName}),
      });
      const data = await response.json();
      if (!response.ok) {
        console.error("Failed to update schema:", data.error);
      }
    } catch (error) {
      console.error("Error updating schema:", error);
    }
  };
  const deleteSchema = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteSchema`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({userId: userId, schema: schema}),
      });
      const data = await response.json();
      if (response.ok) {
        onDelete(schema);
      } else {
        console.error("Failed to delete schema:", data.error);
      }
    } catch (error) {
      console.error("Error deleting schema:", error);
    }
  };

  // JSON response will contain desired email to share to and the schema to share
  const shareSchema = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/shareSchema`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({email: newFieldInput, userId: userId, schema: schema }),
      });
      const data = await response.json();
      if (response.ok) {
        alert(`You shared this schema with ${newFieldInput}`);
      } else {
        alert(`Error: ${data.error}`);
        console.error("Failed to share schema:", data.error);
      }
    } catch (error) {
      console.error("Error sharing schema:", error);
    }
  };

  const handleInputChange = (event) => {
    setNewFieldInput(event.target.value);
  };

  const handleShareSchema = () => {
    shareSchema();
    toggleShareSchemaInput();
  };

  const toggleShareSchemaInput = () => {
    setIsAddingField(!isAddingField);
    setNewFieldInput('');
  };

  const handleDeleteClick = () => {
    if (window.confirm("Are you sure you want to delete this schema?")) {
      deleteSchema();
    }
  };

  const handleGenerateMultipleClick = () => {
    if (isSelectingMultiple) {
      setIsSelectingMultiple(false);
      setSelectedTestCases([]);
    } else setIsSelectingMultiple(true);
  };

  const handleCheckboxChange = (testCase, isChecked) => {
    if (isChecked) {
      setSelectedTestCases([...selectedTestCases, testCase]);
    } else {
      setSelectedTestCases(selectedTestCases.filter((tc) => tc !== testCase));
    }
  };

  const generateMultiple = async () => {
    setIsGenerating(true);
    working = true
    const data = {
      ...schema,
      testCases: selectedTestCases,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generateMultiple`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response)

      const { xml_content, xml_name, override_xml_content, override_xml_name } = response.data.results;

          // Create and download the main XML file
      const mainBlob = new Blob([xml_content], { type: 'application/xml' });
      const mainLink = document.createElement('a');
      mainLink.download = xml_name;
      mainLink.href = URL.createObjectURL(mainBlob);
      document.body.appendChild(mainLink);
      mainLink.click();
      document.body.removeChild(mainLink);

      // If override XML content is present, create and download the override XML file
      if (override_xml_content && override_xml_name) {
          const overrideBlob = new Blob([override_xml_content], { type: 'application/xml' });
          const overrideLink = document.createElement('a');
          overrideLink.download = override_xml_name;
          overrideLink.href = URL.createObjectURL(overrideBlob);
          document.body.appendChild(overrideLink);
          overrideLink.click();
          document.body.removeChild(overrideLink);
      }

      console.log('XML files downloaded successfully');
      setIsSelectingMultiple(false)
      setSelectedTestCases([])
    } catch (error) {
      console.error("There was an error generating the XML!", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (isNewTestCaseAdding && formRef.current && !formRef.current.contains(e.target)) {
      setIsNewTestCaseAdding(false);
    }
    if (isAddingField && shareFormRef.current && !shareFormRef.current.contains(e.target)) {
      setIsAddingField(false);
    }
  };

  useEffect(() => {
    if (isNewTestCaseAdding || isAddingField) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isNewTestCaseAdding, isAddingField]);

  const getTestCases = () => {
    return testCases.map((testCase, index) => (
      <div key={index} className="test-case">
        <SchemaTestCase
          testCase={testCase}
          updateTestCase={updateTestCase}
          updateTestCaseName={updateTestCaseName}
          schemaElements={schemaElements}
          getSchemaXSD={() => schema.schema}
          getSchemaObj={getSchemaObj}
          updateSchemaInBackend={updateSchemaInBackend}
          onRemove={handleRemoveTestCase}
          onDuplicate={handleDuplicateTestCase}
          doesNameExist={doesNameExist}
          isSelectingMultiple={isSelectingMultiple}
          onCheckboxChange={handleCheckboxChange}
          isEditing={testCase.testCaseName === editingTestCase}
          setEditingTestCase={setEditingTestCase}
        />
      </div>
    ));
  };

  const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
  };

  // Handle file selection as well as call the handling of getting the different types of data
  const fileHandling = async (e) => {
    let selectedFile = e.target.files[0];
    file = selectedFile;
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        // Construct the payload with file content and schema.schema object
        const payload = {
          content,
          schema: schema,
        };

        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/updateXSD`, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          // Handle response if needed
          if (response.data.xml_is_approved){
            await updateXSD(content)
          } else{
            console.log("no");
          }

        } catch (error) {
          // Handle error
          console.error('Error updating XSD:', error.response.data);
          alert("Error Updating XSD: " + error.response.data.error);
        }
      };
      reader.readAsText(selectedFile);
    };
  };

  const updateXSD = async (data) => {
      try {
        const fileName = file.name.replace('.xsd', '');

        const updatedSchema = {...schema, fileName: fileName, schema: data}

        await updateSchemaInBackend(updatedSchema);
      } catch (error) {
        console.log("Error in updateXSD:", error);
      }
    };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleAddNewTestCase();
  };

   return (
    <div className="schema-container">
      <div className={`schema-wrapper-buttons ${isOpen ? 'open' : ''}`}>
        <div className="schema-title">
          <p className={`dropdown-button ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
            &gt;
          </p>
          <h2>{schema.fileName}</h2>
        </div>
        <div className="button-holder">
            {isAddingField ? (
                <form ref={shareFormRef} className="share-schema-container">
                  <input
                    type="text"
                    value={newFieldInput}
                    onChange={handleInputChange}
                    placeholder="Email"
                    className="input-box"
                    />
                    <button onClick={handleShareSchema}>
                      Share
                    </button>
                    <button onClick={toggleShareSchemaInput}>
                      Cancel
                    </button>
                </form>
              ) : (
                <div className="share-schema-container">
                  <img className="share-schema-button" title={"Share Schema"} alt={"Share Button"} src={ShareIcon}
                       onClick={toggleShareSchemaInput}/>
                </div>

            )}
          <div className="updateXSD-container">
            <img className="update-xsd-button"
                 title={"Update Schema"}X
                 src={UpdateIcon}
                 alt={"Update Schema"}
                 onClick={triggerFileInput} // Trigger the file input click
            />
            <input
                type="file"
                accept=".xsd"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={fileHandling}
            />
          </div>
          {!isSelectingMultiple && (
              <button onClick={handleGenerateMultipleClick} className="generate-multiple-button">
                Generate Multiple
              </button>
          )}
          {isSelectingMultiple && (
              <button onClick={handleGenerateMultipleClick} className="generate-multiple-button">
                Cancel
              </button>
          )}
          <button onClick={handleDeleteClick}>Remove Schema</button>
        </div>
      </div>
      {isOpen && (
          <div className="schema-wrapper">
            {getTestCases()}
            {isNewTestCaseAdding ? (
            <form ref={formRef} onSubmit={handleFormSubmit} className="new-test-case-container">
              <input
                type="text"
                value={newTestCaseName}
                onChange={handleNewTestCaseNameChange}
                placeholder="Test Case Name"
                className="input-box"
              />
              <select value={selectedFieldName} onChange={handleFieldNameChange} className="input-box">
                <option value="">Select Field Name</option>
                {schemaElements.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={newFieldInput}
                onChange={handleNewFieldInputChange}
                placeholder="Field Input"
                className="input-box"
              />
              <div className="new-test-case-buttons">
                <button type="submit">Submit</button>
                <button type="button" onClick={toggleAddNewTestCase}>
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <div className="schema-options">
              <button className="test-case-btn" onClick={toggleAddNewTestCase}>
                <img src={AddIcon} alt="new test case" title="Add new test case" />
              </button>
              {isSelectingMultiple && (
                <img onClick={generateMultiple} src={GenerateIcon} alt={"Generate"}
                     className={`generate-button ${isGenerating ? 'generating' : ''}`}
                     title="Generate selected test cases" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Schema;
