import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './Signup.css';

const Signup = ({ setUserId }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleCreateUser = async (event) => {
    event.preventDefault();

    const userData = {
      email,
      password
    };
    userData.email = userData.email.toLowerCase();

    const isConfirmed = window.confirm("Do not enter any Personally Identifiable Information (PII) or Protected Health Information (PHI) into this system. By continuing with the sign-up process, you acknowledge that you understand and agree to comply with this policy.");
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      const data = await response.json();
      if (response.ok) {
        setUserId(data.user_id);
        // Save user data to localStorage
        localStorage.setItem('user', JSON.stringify(data));
        navigate('/');
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h2 className="signup-text">Sign Up</h2>
      <form onSubmit={handleCreateUser}>
        <div>
          <input
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              className="signup-email"
          />
        </div>
        <div>
          <input
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              className="signup-pass"
          />
        </div>
        <button type="submit">Sign Up</button>
        <p>Already have an account? <Link to="/login">Log in</Link></p>
        <p>By making an account you agree to our <Link to="https://www.ecm-tek.com/tecasi-copyright-and-terms-of-use">Terms of Use</Link>.</p>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Signup;