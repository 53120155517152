import React, { useState, useEffect } from 'react';
import './NestedDropdown.css';

const NestedDropdown = ({ rootElements, setPrimaryKey, setUsedComplexTypes }) => {
  const [selectedElement, setSelectedElement] = useState('');
  const [primaryKeySelectionLayers, setPrimaryKeySelectionLayers] = useState([]);

  useEffect(() => {
    // Reset selectedElement and layers when rootElements changes
    setSelectedElement('');
    setPrimaryKeySelectionLayers([]);
  }, [rootElements]);

  const handleSelectElement = (element) => {
    setSelectedElement(element);
    if (rootElements && rootElements.complex) {
      setPrimaryKeySelectionLayers([...primaryKeySelectionLayers, element]);

      // Update usedComplexTypes to replace previous selections
      setUsedComplexTypes(prevUsedTypes => {
        const updatedTypes = [...prevUsedTypes];

        // Find and replace the previously selected element if exists
        const index = updatedTypes.indexOf(selectedElement);
        if (index !== -1) {
          updatedTypes.splice(index, 1, element);
        } else {
          updatedTypes.push(element);
        }

        return updatedTypes;
      });
    } else {
      setPrimaryKey(element);
    }
  };

  // Check if rootElements and rootElements.elements are defined
  if (!rootElements || !rootElements.elements) {
    return null; // or handle loading state or empty state
  }

  return (
    <div className="nested-dropdown">
      <select
        value={selectedElement}
        onChange={(e) => handleSelectElement(e.target.value)}
        className="dropdown"
      >
        <option value="" disabled>Select an element</option>
        {Object.keys(rootElements.elements).map((element) => (
          <option key={element} value={element}>
            {element}
          </option>
        ))}
      </select>
      {primaryKeySelectionLayers.map((layer, index) => (
        <div key={index} className="nested-layer">
          <NestedDropdown
            rootElements={rootElements.elements[layer]}
            setPrimaryKey={setPrimaryKey}
          />
        </div>
      ))}
    </div>
  );
};

export default NestedDropdown;
