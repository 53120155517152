import { useState} from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css';

// Amplify
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from "../../aws-exports.js";

Amplify.configure(awsconfig);



const Login = ({ setUserId }) => {
  const navigate = useNavigate();
  const [loginInitiated, setLoginInitiated] = useState(false)
  const handleLogin = async (session) => {
    try {
      console.log(process.env.REACT_APP_BACKEND_URL)
      console.log(`${process.env.REACT_APP_BACKEND_URL}/login`)
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: session.tokens.idToken.toString() }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setUserId(data.user_id);
        navigate('/');
      } else {
        console.error('Login error:', data);
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <Authenticator
    signUpAttributes={[
      'name'
    ]}>
      {({ user }) => {
        if (user && !loginInitiated) {
          setLoginInitiated(true);
          fetchAuthSession().then((session) => {
            handleLogin(session)
            console.log(loginInitiated);
          });
        }
      }}
    </Authenticator>
  );
};

export default Login;