import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import XMLGeneration from "./pages/xmlGeneration/XMLGeneration";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

function App() {
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.user_id);
      setUserDetails(parsedUser);
    }
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`, {
            method: 'GET',
            credentials: 'include'
          });
          if (response.ok) {
            const data = await response.json();
            setUserDetails(data);
          } else {
            console.error('Failed to fetch user details');
            setUserId(null);
            setUserDetails(null);
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          setUserId(null);
          setUserDetails(null);
        }
      }
    };
    fetchUserDetails();
  }, [userId]);

  return (
    <Router>
      <div className="App">
        <Navbar userId={userId} setUserId={setUserId} setUserDetails={setUserDetails} />
        <Routes>
          <Route
            path="/login"
            element={userId ? <Navigate to="/" /> : <Login setUserId={setUserId} />}
          />
          <Route
            path="/signup"
            element={userId ? <Navigate to="/" /> : <Signup setUserId={setUserId} />}
          />
          <Route
            path="/"
            element={userId ? (
              <>
                <XMLGeneration userDetails={userDetails} userId={userId}/>
              </>
            ) : (
              <Navigate to="/login" />
            )}
          />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;